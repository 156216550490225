<template lang="pug">
  div
    back-button
    div.samolet
      img(src="@/assets/pole.jpg")
</template>

<script>
import BackButton from '@/components/BackButton'
import CardsColumn from '@/components/projects/CardsColumn'
import cards from '@/assets/cards'

export default {
  name: 'Projects',
  components: {CardsColumn, BackButton},
  data() {
    return {
      cards: cards,
    }
  },
}
</script>

<style scoped lang="stylus">
.samolet
  display flex
  flex-direction row
  justify-content center
</style>
