<template lang="pug">
  h1
    slot
</template>

<script>
export default {
  name: 'h0',
}
</script>

<style scoped lang="stylus">
h1
  font-size 5rem
  font-family 'Fira Code', monospace
  font-weight normal
</style>
